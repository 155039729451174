.wrapper {
  margin-inline-start: 33px;
  @apply flex items-center mbs-2 mbe-2 mie-4;
}

.compactWrapperMarginInline {
  margin-inline-start: 21px;
}

.text {
  @apply flex-grow text-sm leading-tight;
}

.icon {
  line-height: 0;
  @apply self-start mbs-px mbe-0 mis-0 mie-2;
}

.timestamp {
  @apply box-border text-gray-50 mis-2;
}
