.backgroundWrapper {
  @apply rounded w-full flex flex-row flex-1 bg-transparent pbs-1 pbe-2 pis-1 pie-1 mis-1;
}

.wrapper {
  @apply flex flex-1 items-center relative w-full px-2 py-[5px];
}

.wrapper > div {
  -webkit-tap-highlight-color: transparent;
}

.highlighted {
  @apply bg-gray-5;
}

.overflowHidden {
  @apply overflow-hidden;
}

.compact {
  @apply py-px px-2;
}

.expandedWithPermissions {
  @apply px-2;
}

.expandedWithoutPermissions {
  @apply py-2;
}
