.wrapper {
  @apply flex bg-white;
}

.isMessage {
  @apply self-start;
}

.ellipsisWrapper {
  @apply flex p-2 mr-2;
}

.ellipsis {
  @apply text-gray-50 hover:text-gray-100 fill-current;
}