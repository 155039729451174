.iconWrapper {
  @apply inline-block mis-0.5;
}

.iconWrapper > svg {
  @apply relative block-start-0.5;
}

.roleLabel {
  line-height: 13px;
  border-radius: 7px;
  bottom: 1px;
  @apply bg-gray-50 text-xs py-0 px-1 inline-block mis-1 text-white relative;
}

.name {
  @apply font-bold leading-none inline-block break-all;
}
