.timeReference {
  @apply text-gray-50 text-sm leading-[17px] mis-1.5 mie-0;
}

.textWrapper {
  @apply break-normal mis-2 mbs-0;
}

.compact {
  @apply mbs-1;
}

.compactTime {
  @apply mis-0 mie-4;
}

.info {
  @apply text-sm leading-tight;
}

.compactInfo {
  @apply flex items-center;
}

.expandedMessage {
  @apply mbs-1 leading-tight;
}

.expandedSubText {
  @apply flex h-[17px] items-center;
}