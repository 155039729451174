.notificationWrapper {
  @apply flex items-center m-2 px-3 py-0;
}

.actionableWrapper {
  @apply flex items-center m-2 p-3 bg-gray-5 rounded;
}

.icon {
  @apply self-start mbs-px mbe-0 mis-px mie-2;
}

.actionableWrapper .icon svg path {
  @apply fill-current text-primary;
}

.actionableWrapper .icon svg path:hover {
  @apply fill-current text-primary;
}

.acceptedText {
  @apply text-gray-50 mbs-0.5 block text-sm leading-tight;
}

.acceptedText svg {
  @apply inline align-bottom mie-0.5;
}

.acceptBtn {
  @apply btn btn-xs btn-primary;
}

.pendingText {
  @apply text-sm leading-tight text-gray-80 mis-3 mie-2;
}

.compact {
  @apply pis-3;
}

.expanded {
  @apply pis-6;
}

.wrapper {
  @apply flex flex-grow;
}

.notice {
  @apply text-sm mie-3 leading-tight;
}

.timestamp {
  @apply text-gray-50;
}

.hide {
  @apply hidden;
}