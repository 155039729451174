.wrapper {
  -ms-scroll-chaining: none;
  -webkit-overflow-scrolling: touch;
  padding-block-end: 5px;
  @apply flex flex-1 relative justify-center overflow-y-auto overflow-x-hidden overscroll-y-contain h-full m-0;
}

.listContainer {
  @apply w-full h-full;
}

.objectList {
  @apply m-0 relative block-end-0 w-full min-h-full flex-1 flex flex-col;
}

.objectList > :first-child {
  @apply mbs-auto;
}

.newMessageButtonWrapper {
  @apply absolute w-auto shadow-1 overflow-visible self-center rounded-[64px] z-[1];
}

.loadingWrapper {
  @apply flex box-border w-full h-full justify-center items-center;
}
